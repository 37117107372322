<template>
    <v-row class="ma-0">
        <v-col cols="12" align="center">
            <span class="d-block subtitle-1 font-weight-bold">Total</span>
            <span class="display-2 font-weight-light">{{ total[0] }}</span>
            <span class="display-1 font-weight-light">/{{ total[1] }}</span>
            <span class="d-block subtitle-2 font-weight-regular">personas capacitadas / total personas </span>
        </v-col>
        <v-col cols="4" align="center">
            <span class="d-block subtitle-1 font-weight-bold">Este mes</span>
            <span class="d-block display-1 font-weight-light">{{ actual_month }}</span>
            <span class="d-block subtitle-2 font-weight-regular">personas</span>
        </v-col>
        <v-col cols="4" align="center">
            <span class="d-block subtitle-1 text-capitalize font-weight-bold">{{ last_month_name }}</span>
            <span class="d-block display-1 font-weight-light">{{ last_month }}</span>
            <span class="d-block subtitle-2 font-weight-regular">personas</span>
        </v-col>
        <v-col cols="4" align="center">
            <span class="d-block subtitle-1 text-capitalize font-weight-bold">{{ last_month_two_name }}</span>
            <span class="d-block display-1 font-weight-light">{{ last_two_month }}</span>
            <span class="d-block subtitle-2 font-weight-regular">personas</span>
        </v-col>
    </v-row>
</template>

<script>
import moment from 'moment'

export default {
    props: ['value'],
    computed: {
        total() {
            if(!this.value || !this.value.total) return [0, 0];
            return this.value.total;
        },
        actual_month() {
            if(!this.value) return 0;
            return this.value.month;
        },
        last_month() {
            if(!this.value) return 0;
            return this.value.last_month;
        },
        last_two_month() {
            if(!this.value) return 0;
            return this.value.last_month_2;
        },
        last_month_name() {
            return moment().subtract(1, 'month').format("MMMM");
        },
        last_month_two_name() {
            return moment().subtract(2, 'month').format("MMMM");
        }
    }
}
</script>